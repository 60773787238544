
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import * as types from './form.types';
import { removeHiddenFields, runCustomValidator } from '@/components/form/utils';
import { randId } from '@/utils/components';

type VObs = InstanceType<typeof ValidationObserver>;

@Component({
  components: {
    'g-select': () => import('@/components/gsk-components/GskSelect.vue'),
    'g-multi-select': () => import('@/components/gsk-components/GskMultiSelect.vue'),
    'g-radio': () => import('@/components/gsk-components/GskRadioGroup.vue'),
    'g-checkbox-group': () => import('@/components/gsk-components/GskCheckboxGroup.vue'),
    'g-checkbox': () => import('@/components/gsk-components/GskCheckbox.vue'),
    'g-switch': () => import('@/components/gsk-components/GskSwitch.vue'),
    'g-input-chips': () => import('@/components/gsk-components/GskInputChips.vue'),
    'g-text-field': () => import('@/components/gsk-components/GskTextfield.vue'),
    'g-people-picker': () => import('@/components/gsk-components/GskPeoplePicker.vue'),
    'g-people-picker-table': () => import('@/components/PeoplePickerTable.vue'),
    //'repo-picker': () => import('@/components/RepoPicker.vue'),
    'g-image-upload': () => import('@/components/gsk-components/GskImageUpload.vue'),
    'key-value-input': () => import('@/components/form/KeyValueInput/KeyValueInput.vue'),
    'rpa-category-input': () => import('@/components/CategoryInput.vue'),
    'g-autocomplete': () => import('@/components/gsk-components/GskAutocomplete.vue'),
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ValidatedForm extends Vue {
  @Prop({ type: Array, required: true }) value!: types.FormField[];
  @Prop({ type: Object, default: () => ({}) }) serverErrors!: types.ServerErrors;
  @Prop(Boolean) disabled!: boolean;
  $refs!: {
    topObserver: VObs;
  };

  get fields(): types.FormField[] {
    return removeHiddenFields(this.value);
  }

  fieldStyle(field: types.FormField): { gridColumn?: string } {
    if (field.layout?.gridColumn) {
      return { gridColumn: field.layout?.gridColumn };
    }
    return {};
  }

  @Watch('value', { deep: true })
  handleValueChange(): void {
    // forces dependent required_if fields to get their
    // status properly updated
    this.$refs.topObserver.validate({ silent: true });
  }

  @Watch('serverErrors', { deep: true })
  handleServerErrors(errors: types.ServerErrors): void {
    this.setServerErrors(errors);
  }

  setServerErrors(errors: types.ServerErrors): void {
    this.$refs.topObserver?.setErrors(errors);
  }

  asyncValidators: Record<string, string> = {};
  asyncValidate = debounce(this.customValidate, 1000);
  customValidate(field: types.FormField): void {
    if (field.customValidator) {
      const id = randId();
      // try to mitigate race condition if there's multiple reqs out at once
      this.$set(this.asyncValidators, field.key, id);
      runCustomValidator(field).then(res => {
        if (res.hasErr && this.asyncValidators[field.key] === id) {
          this.setServerErrors(res.serverErrors);
        }
      });
    }
  }

  clearSelectValue(field: types.FormField) {
    field.value = '';
  }

  onChangeInputChips(field: types.FormField, value: types.FormField['value']): types.FormField[] {
    if (value === undefined) {
      return cloneDeep(this.value); 
    }
    let val = value;
    const { inputChipsProps } = field;
    if (inputChipsProps?.unique && typeof value === 'string' && value.indexOf(',') > -1) {
      val = uniq(value?.split(',')).join(',');
    }
    return this.onChange(field, val);
  }

  mounted(): void {
    // next tick didn't work here
    // and there's no clear way to know that
    // all of the validation layers are rendered and ready
    setTimeout(async () => {
      this.$refs.topObserver?.validate({ silent: true }).then(() => {
        if (Object.keys(this.serverErrors).length) {
          this.$refs.topObserver?.setErrors(this.serverErrors);
        }
      });
    }, 1000);
  }

  isDisabled(field: types.FormField): string | boolean {
    return field.attrs?.disabled || this.disabled;
  }

  get isDev(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  toNumber(val: unknown): number | null {
    return val === null || val === '' ? null : Number(val);
  }

  @Emit('input')
  onChange(field: types.FormField, value: types.FormField['value']): types.FormField[] {
    // this is an expensive operation to do on every key stroke
    // but hasn't been a problem with our forms so far
    // a new container array + shallow cloning the target field is probably adequate
    const model = cloneDeep(this.value);
    const mf = model.find(modelField => modelField.key === field.key);
    if (mf) {
      if(mf.type === 'number' && value === null)
      {
        value = 0;
      }
      mf.value = value;
      if (mf.customValidator) {
        this.asyncValidate(mf);
      }
    }
    return model;
  }

  validation(field: types.FormField): types.FormFieldValidaiton | Record<string, never> {
    if (field.validation === undefined) {
      return {
        vid: field.key,
      };
    }
    const isRadio = field.type === 'radio';
    const validation: types.FormFieldValidaiton = { ...field.validation, vid: field.key };
    if (isRadio) {
      validation.immediate = true;
      validation.mode = 'aggressive';
    }
    if (validation.name === undefined) {
      validation.name = field.label;
    }

    return validation;
  }

  isValid(v: { errors: string[] }): boolean {
    return v.errors.length === 0;
  }

  helpText(field: types.FormField): string {
    return field.helpText || ' ';
  }

  textFieldType(
    field: types.TextField | types.NumberField,
  ): 'textarea' | 'text' | 'number' | string | boolean {
    if (field.type === 'long-text') {
      return 'textarea';
    }
    if (field.type === 'number') {
      return field.type;
    }
    if (field.attrs?.type) {
      return field.attrs.type;
    }

    return 'text';
  }
}
