import { FormField, ServerErrors } from '@/components/form/form.types';
import keyBy from 'lodash/keyBy';

/**
 * Old validator from before we brought in vee-validate
 * @param form
 */
export function isValid(form: FormField[]) {
  return form.reduce((isValid: boolean, field) => {
    if (isValid) {
      if (field.required && !field.hide) {
        if (Array.isArray(field.value)) {
          return !!field.value.length;
        }

        return !!field.value;
      }
    }
    return isValid;
  }, true);
}

export function runCustomValidator(field: FormField) {
  return runCustomValidators([field]);
}

export function runCustomValidators(fields: FormField[]) {
  const errs: Promise<[string, string]>[] = [];
  for (const field of fields) {
    if (field.customValidator) {
      errs.push(field.customValidator(field.value as never).then(message => [field.key, message]));
    }
  }
  return Promise.all(errs).then(e => {
    const serverErrors: ServerErrors = {};
    let hasErr = false;
    for (const [key, message] of e) {
      if (message) {
        serverErrors[key] = message;
        hasErr = true;
      }
    }
    return { serverErrors, hasErr };
  });
}

export function removeHiddenFields(fields: FormField[]): FormField[] {
  const keyedFiels = keyBy(fields, 'key');
  return fields.filter(field => {
    if (field.hideIf) {
      if (typeof field.hideIf === 'function') {
        return !field.hideIf(fields, field);
      } else {
        if (keyedFiels[field.hideIf.fieldKey]?.value === field.hideIf.fieldValue) {
          return false;
        }
      }
    }

    return true;
  });
}
