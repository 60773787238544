import { BaseUser } from '@/types/users.types';
import { GithubRepo } from '@/types/publishing.types';
import { UITab } from '@/types';
import { LogicalBotCategoryFormFieldData } from '@/types/edit-bot.types';
import { Choice } from '@gsk-tech/gsk-autocomplete/gsk-autocomplete-base';

/**
 * https://logaretm.github.io/vee-validate/api/validation-provider.html#props
 *
 * used in ValidatedForm component, comments below regarding interface fields are
 * pertaining to use within that context
 */
export interface FormFieldValidaiton {
  vid?: string; // will set to key of form field even if provided
  rules?: any;
  name?: string; // will use field.label if not provided
  mode?: 'aggressive' | 'passive' | 'lazy' | 'eager'; // eager is default and also best
  immediate?: boolean;
  bails?: boolean;
  skipIfEmpty?: boolean;
  debounce?: number;
  tag?: string;
  slim?: boolean;
  disabled?: boolean; // will set to true if field.
  customMessages?: Record<string, string>;
}

export type HideFunc<Key, Col extends FormField[] = FormField[]> = (
  fields: Col,
  field: FormField<Key>,
) => boolean;
export interface FormFieldBase<T, Key> {
  type: FieldType;
  label: string;
  outputLabel?: string; // for when you want to display `label`: `value` later and `label` is stupid
  value: T;
  key: Key;
  required?: boolean;
  hide?: boolean; // removes field from render and validation
  helpText?: string;
  leadingText?: string;
  leadingHeader?: string;
  attrs?: Record<string, string|boolean>;
  validation?: FormFieldValidaiton;
  max?: string
  customValidator?: (value: T) => Promise<string>;
  hideIf?:
    | HideFunc<Key>
    | {
        // hide this field if another field has some value
        // e.g. a switch is off
        fieldKey: string;
        fieldValue: unknown;
      };
  layout?: {
    gridColumn?: string;
  };
  inputChipsProps?: {
    unique: boolean;
  };
}

export type FieldType =
  | 'text'
  | 'long-text'
  | 'number'
  | 'select'
  | 'multi-select'
  | 'radio'
  | 'switch'
  | 'checkbox'
  | 'checkbox-single'
  | 'people-picker'
  | 'people-picker-table'
  | 'github-repo'
  | 'key-value'
  | 'image-upload'
  // don't like having a non-generic input here, need to refactor it into a
  // reusable component but don't have time to figure it out right now
  | 'rpa-categories'
  | 'date'
  | 'input-chips'
  | 'autocomplete';

export interface KeyValue {
  key: string;
  value: string;
  id: string;
}

export interface KeyValueField<Key = string> extends FormFieldBase<KeyValue[], Key> {
  type: 'key-value';
  keyLabel: string;
  valueLabel: string;
  keyValidation?: FormFieldValidaiton;
  valueValidation?: FormFieldValidaiton;
  allowEmpty?: boolean;
  fieldLabels?: boolean;
  keyType?: string;
  valueType?: string;
}

export interface SelectOption<T = any, Key = string> {
  value: string;
  label?: string;
  description?: string;
  key?: Key;
  disabled?: boolean;
  tooltip?: string;
  extra?: T;
}
export type SelectValue = SelectOption['value'];
export interface SelectField<Key = string> extends FormFieldBase<SelectValue, Key> {
  type: 'select';
  enhanced?: boolean;
  options: SelectOption[] | string[];
  showClear?: boolean;
}
export interface MultiSelectField<Key = string> extends FormFieldBase<SelectValue[], Key> {
  type: 'multi-select';
  options: SelectOption[] | string[];
}
export interface PeoplePickerField<Key = string> extends FormFieldBase<BaseUser[], Key> {
  type: 'people-picker';
  single?: boolean;
}
export interface PeoplePickerTableField<Key = string> extends FormFieldBase<BaseUser[], Key> {
  type: 'people-picker-table';
  tableFieldKey: keyof BaseUser;
  tableLabel: string;
}
export interface RadioField<Key = string> extends FormFieldBase<SelectValue, Key> {
  type: 'radio';
  options: SelectOption[] | string[];
  inline?: boolean;
}
export interface CheckboxField<Key = string> extends FormFieldBase<SelectValue[], Key> {
  type: 'checkbox';
  options: SelectOption[] | string[];
}

export interface CheckboxSingleField<Key = string> extends FormFieldBase<boolean, Key> {
  type: 'checkbox-single';
}

export interface SwitchField<Key = string> extends FormFieldBase<boolean, Key> {
  type: 'switch';
}

export interface TextField<Key = string> extends FormFieldBase<string, Key> {
  type: 'text' | 'long-text';
}

export interface DateField<Key = string> extends FormFieldBase<string, Key> {
  type: 'date';
}

export interface NumberField<Key = string> extends FormFieldBase<number | null, Key> {
  type: 'number';
}

export interface GithubRepoField<Key = string> extends FormFieldBase<GithubRepo | null, Key> {
  type: 'github-repo';
}

export interface ImageUploadField<Key = string> extends FormFieldBase<string[], Key> {
  type: 'image-upload';
}

export interface InputChipsField<Key = string> extends FormFieldBase<string, Key> {
  type: 'input-chips';
}

export interface RpaCategoryField<Key = string>
  extends FormFieldBase<LogicalBotCategoryFormFieldData[], Key> {
  type: 'rpa-categories';
}

export interface AutoCompleteField<Key = string> extends FormFieldBase<Choice, Key> {
  type: 'autocomplete';
  choices: Choice[];
}

export type FormField<Key = any> =
  | TextField<Key>
  | DateField<Key>
  | NumberField<Key>
  | SelectField<Key>
  | MultiSelectField<Key>
  | PeoplePickerField<Key>
  | RadioField<Key>
  | SwitchField<Key>
  | CheckboxField<Key>
  | CheckboxSingleField<Key>
  | GithubRepoField<Key>
  | KeyValueField<Key>
  | ImageUploadField<Key>
  | InputChipsField<Key>;

export interface ExtendedPropertiesDetails {
  fields: FormField[];
}

export function isSelectOptions(options: SelectOption[] | string[]): options is SelectOption[] {
  return !(typeof options[0] === 'string');
}

export interface FormWizardStep<Key extends string = string, T = unknown> {
  key: string;
  heading: string;
  subheading?: string;
  nav: UITab;
  fields: FormField<Key>[];
  data?: T;
}

export type ServerErrors = Record<string, string | string[]>;
